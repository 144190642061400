import {
  Dialog as AriakitDialog,
  DialogProps as AriakitDialogProps,
  DialogStoreProps,
  useDialogStore as useAriakitDialogStore,
} from "@ariakit/react";
import clsx from "clsx";
import { useRef } from "react";

import { Component, createComponentUtils } from "../__utils/atlas";
import { createDefaultProps } from "../__utils/react";
import { useDialogOpen } from "../dialog/DialogOpenContext";
import { NotistackDomRootId } from "../provider/Provider";

// config
// ------

const COMPONENT_NAME = "DialogV2";

const { el, createComponent } = createComponentUtils(COMPONENT_NAME);

// hooks
// -----

export type DialogStore = ReturnType<typeof useDialogStore>;

export function useDialogStore(props?: DialogStoreProps) {
  const { incrementDialogCount, decrementDialogCount } = useDialogOpen();
  const prevOpenRef = useRef<boolean | null>(null);

  return useAriakitDialogStore({
    animated: true,
    ...props,
    setOpen: (open) => {
      // Report to global provider if any dialog is open
      if (open !== prevOpenRef.current) {
        if (open) {
          incrementDialogCount();
        } else {
          decrementDialogCount();
        }
        prevOpenRef.current = open;
      }

      if (props?.setOpen) {
        props.setOpen(open);
      }
    },
  });
}

// root
// ----

export type DialogProps = Omit<AriakitDialogProps, "store"> & {
  /** Ariakit dialog store. Required in order to ensure the custom hook is used, and the dialog is controlled. */
  store: DialogStore;
  /**
   * The size of the dialog content.
   * @default "small"
   */
  size?: "xs" | "small" | "small-plus" | "medium" | "medium-plus" | "large";

  /**
   * The dialog variant.
   * @default "default"
   */
  variant?: "default" | "sheet" | "fullscreen";
};

const DEFAULT_CONTENT_PROPS = createDefaultProps<DialogProps>()({
  size: "small",
  variant: "default",
} as const);

export const Dialog = createComponent<Component<DialogProps>>(
  ({
    size = DEFAULT_CONTENT_PROPS.size,
    variant = DEFAULT_CONTENT_PROPS.variant,
    ...props
  }) => {
    const state = props.store.useState();

    return (
      <AriakitDialog
        backdrop={
          <div className={`${el`backdrop`} size-${size} variant-${variant}`} />
        }
        hideOnInteractOutside={props.backdrop !== false}
        portal
        preventBodyScroll={variant !== "sheet"}
        {...props}
        className={clsx(
          `${el`content`} size-${size} variant-${variant}`,
          props.className
        )}
        getPersistentElements={() =>
          document.querySelectorAll(
            `#${NotistackDomRootId}, div.sm-survey > div > div, .intercom-app, .intercom-lightweight-app, .tour__editor`
          )
        }
      >
        {state.open || state.animating ? props.children : null}
      </AriakitDialog>
    );
  }
);
